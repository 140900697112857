.matchmaker-checkbox {
  $block: &;
  $black: #000;
  display: block;
  margin-top: 15px;
  float: left;

  &--failed {
    #{$block}__label #{$block}__span {
      border-color: $brand-danger;
    }
  }

  &__input {
    visibility: hidden;
    width: 0;
  }

  &__label {
    display: block;
    float: left;
    margin-bottom: 10px;

    &::before {
      content: ' ';
      width: 0 !important;
      height: 0 !important;
      border: none !important;
      margin: 0 !important;
    }
  }

  &__span {
    display: inline-block;
    width: 30px;
    height: 30px;
    margin-right: 15px;
    vertical-align: middle;
    cursor: pointer;
    font-size: 1.60px;
    font-weight: 300;
    float: left;
    background: #F0F4F5;
    border: 1px solid #787878;
  }

  &__text {
    font-size: 13px;
    display: block;
    overflow: hidden;
    margin-top: -25px;
  }

  &__note {
    font-style: italic;
    font-weight: 100;
    margin-top: 15px;
    display: block;
  }

  &__checkmark svg {
    display: block;
    margin-left: 7px;
    margin-top: 6px;
  }

  &__input:not(:checked) + label &__span &__checkmark {
    display: none;
  }

  &__link {
    color: $black;
    text-decoration: underline;

    &:hover,
    &:focus,
    &:active {
      color: $black;
    }
  }
}
