.sidenav {
  padding: 10px;
  
  ol, ul {
    list-style: none;
    padding: 0;

    li:not(:last-child) {
      margin-bottom: .5em;
    }
  }

  ol.nav-level1 {
		margin: 0;
		padding: 0;
		list-style: none;
		>li {
			list-style: none;
			border-top: 2px solid #fff;
			width: 100%;
			&:first-of-type {
				border-top: 0;
			}
			>ol {
				>li {
					border-top: 2px solid #a5a5a5;
					>a {
						background-color: #4f4f4f;
						color: #fff;
						&:hover {
							background-color: #a5a5a5;
						}
					}
				}
				>li.is-current {
					border-top: 2px solid #a5a5a5;
					>a {
						background-color: #4f4f4f;
						font-weight: 700;
						&:active {
							background-color: #a5a5a5;
							color: #fff;
						}
						&:focus {
							background-color: #a5a5a5;
							color: #fff;
						}
						&:hover {
							background-color: #a5a5a5;
							color: #fff;
						}
					}
				}
				display: none;
			}
			a {
				background-color: #f0f4f5;
				display: block;
				padding: .5em 1em;
				position: relative;
				font-size: 16px;
				color: inherit;
				&:active {
					background-color: #4f4f4f;
					color: #fff;
					text-decoration: none;
				}
				&:focus {
					background-color: #4f4f4f;
					color: #fff;
					text-decoration: none;
				}
				&:hover {
					background-color: #4f4f4f;
					color: #fff;
					text-decoration: none;
				}
			}
		}
		>li.is-current {
			border-top: 2px solid;
			border-top-color: #fd0 !important;
			>a {
				background-color: #fd0;
				font-weight: 400;
				&:active {
					background-color: #fd0 !important;
					color: #333 !important;
				}
				&:focus {
					background-color: #fd0 !important;
					color: #333 !important;
				}
				&:hover {
					background-color: #fd0 !important;
					color: #333 !important;
				}
			}
			ol {
				display: inherit;
			}
		}
	}
	#contact-box {
		&:not(.ngIsLoaded) {
			display: none;
		}
	}
	.contact-box {
		.loader {
			&:not([class*=show-ng]) {
				display: none;
			}
		}
		.no-op {
			opacity: 0;
			height: 0;
		}
		a {
			margin-top: 15px;
		}
		h4 {
			font-size: 1.2em;
		}
		p {
			margin-bottom: 0;
		}
	}
}
