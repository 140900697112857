.styled-table {
  font-size: 16px;
  font-weight: 300;


  tr:nth-child(even) {
    background: #e8e8e9;
  }

  td {
    font-size: 16px;
    font-weight: 300;
    padding: 8px;
    border-top: 1px solid #ddd;
    border-left: 1px solid #ddd;

    &:first-child,
    &:nth-child(3),
    &:nth-child(4) {
      border-left: none;
    }
  }
}
