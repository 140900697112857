.container
{
	@media (min-width: $screen-slg-min) {
		padding: 0 120px;
	}
}
.container.content
{
	background-color: #fff;
	padding-top: 60px;
	padding-bottom: 60px;
}