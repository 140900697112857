/*
.content
{
	padding: 0 15px 15px 15px;
}
.box
{
	background: $container-color;
	padding-bottom: 15px;
}
@media (min-width : $screen-md-min)
{
	.wrapper
	{
		width: 78.91%;
	}
	.sidenav
	{
		width: 19%;
		margin-right: 15px;
	}
}
@media (min-width : 1200px)
{
	.wrapper
	{
		width: 79.685%;
	}
}
*/


.sidenav
{
	padding: 10px;

	ol.nav-level1
	{
		margin: 0;
		padding: 0;
		list-style: none;

		>li
		{
			list-style: none;
			border-top: 2px solid #fff;
			width: 100%;
			&:first-of-type
			{
				border-top: 0;
			}
			a
			{
				background-color: $light_grey;
				display: block;
				padding: .5em 1em;
				position: relative;
				font-size: 16px;
				color: inherit;
				&:hover, &:active, &:focus
				{
					background-color: #4f4f4f;
					color: #fff;
					text-decoration: none;
				}
			}
			>ol
			{
				display: none;
				>li
				{
					border-top: 2px solid #a5a5a5;
					>a
					{
						background-color: #4f4f4f;
						color: #fff;
						&:hover
						{
							background-color: #a5a5a5;
						}
					}
					&.is-current
					{
						border-top: 2px solid #a5a5a5;
						>a
						{
							background-color: #4f4f4f;
							font-weight: 700;
							&:hover, &:active, &:focus
							{
								background-color: #a5a5a5;
								color: #fff;
							}
						}
					}
				}
			}
			&.is-current
			{
				border-top-color: #a5a5a5;
				border-top: 2px solid;
				>a
				{
					background-color: #fd0;
					font-weight: 400;
					&:hover, &:active, &:focus
					{
						background-color: #fd0 !important;
						color: #333 !important;;
					}
				}
				ol
				{
					display: inherit;
				}
			}
		}
	}

	ol.nav-level1>li.is-current{
		border-top-color: #fd0 !important;
	}

	#loginButton, #logoutButton{
		cursor: pointer;
	}

	.contact-box{

		.loader:not([class*="show-ng"]){
			display: none;
		}

		.no-op{
			opacity: 0;
			height: 0;
		}

		a{
			margin-top: 15px;
			cursor: pointer;
		}

		h4{
			font-size: 1.2em;
		}

		p{
			margin-bottom: 0;
		}
	}

	#contact-box:not(.ngIsLoaded){
		display: none;
	}
}

@media(min-width: $screen-md-max){
	#sec-nav{
		display: none;
	}

	#sec-nav-col{
		display: none;
	}
}
