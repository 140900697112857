html
{
	@media (min-width: $screen-slg-min) {
	    //background-color: #f0f4f5;
	}
}
body
{
	background-color: initial;
}
h1, h2, h3, h4, h5
{
	margin-top: 0;
}

a.logo.pull-right{
    cursor: pointer;
}

#logoutButton{
    //display: none;
}

a:focus{
    outline: none !important;
}

a.back.button{
    cursor: pointer;
}