.messageOverlay
{
  &:not(.visible){
      display: none;
  }
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0, 0.8);
  top: 0;
  left: 0;
  z-index: 2;
  .messageBox
  {
    width: 90%;
    margin: 5%;
    padding: 30px;
    background: #fff;
    display: inline-block;
    @media(min-width: $screen-sm-min)
    {
      position: absolute;
      width: ($screen-sm-min - 30);
      min-height: 200px;
      top: 50%;
      left: 50%;
      margin-left: - ($screen-sm-min - 30) / 2;
      margin-top: -300px;
    }
  }
  button
  {
    float: right;
    &.button2
    {
      background-color: #f0f4f5;
      padding: 0.645em 1.29em 0.645em 1.29em;
      &:hover
      {
        background-color: darken($text-bg, 10%);
      }
    }
  }
}