.calculations{
    .delete, .result, .delete-confirm, .delete-no{
        padding: 0.645em 1.8em 0.645em 1.29em;
        border: 0;
        color: black;
        background-color: white;
        border-radius: 0;
        width: 120px;
        float: right;
        text-decoration: none;

        &:hover{
            cursor: pointer;
        }
    }
    .submit-arrow{
        float: right;
    }
    .delete{
        background-color: $text-bg;
        
        &:hover
        {
            background-color: darken($text-bg, 10%);
        }
    }
    
    .delete-confirm{
        background-color: #FF4940;
        color: white;
        
        &:hover
        {
            background-color: darken(#FF4940, 10%);
        }
    }
    
    .delete-no {
        background-color: $text-bg;
    }
    
    .confirmation{
        float: right;
    }
}

@media(min-width: $screen-sm-min){
    table.table-scollable
    {
        display: table;
        width: 100%;
    }
}
@media(max-width: $screen-xs-max){
 
    .delete{
        width: 100%;
    }
    
}
#savedCalcs
{
    .pagination
    {
        margin: 0 auto;
        position: relative;
        display: block;
        a
        {
            border-radius: 0px;
        }
    }
}
#savedCalcs table
{
    tr
    {
        background: #fff;
    }
    tr:nth-child(even)
    {
        background: #f9f9f9;
    }
    td
    {
        border-top: none;
        width: 33%;
        display: table-cell;
    }
    td:last-of-type
    {
        padding-right: 0;
    }
}

.pagination > li {
  &:before {
    content: '' !important;
  }
}
