.invalid-select.ng-invalid-required{
	border: 1px solid red !important;

	&:focus{
		box-shadow: 0 0 0 2px red !important;
	}
}
.text-input
{
	width: 100%;
	float: left;
	margin-bottom: 10px;
	position: relative;
	label, input, select
	{
		width: 100%;
		font-size: 14px;
	}
	input
	{
		padding: 10px;
	}
	//label:focus, input:focus
	//{
	//	-webkit-box-shadow: 0 0 0 2px #4f4f4f;
	//	box-shadow: 0 0 0 2px #4f4f4f;
	//	color: black;
	//	outline: none;
	//}
	//input
	//{
	//	padding: 1em;
	//	border: 1px solid #787878;
	//	color: black;
	//	background-color: white;
	//	border-radius: 0;
    	//margin-top: 2px;
	//}
}

@media (min-width: 400px) {
	.text-input.halfed{
		width: 50%;

		&.angle{
			padding-right: 5px;
		}
		&.height{
			padding-left: 5px;
		}
	}
}
.helpbutton,.helpbuttontop
{
	background-color: #fd0;
	padding: 4px 9px;
	color: #333;
	border-radius: 100%;
	display: inline-block;
	width: 25px;
	height: 25px;
	margin-left: 5px;
	margin-bottom: 5px;
	float: right;
	@media(min-width: $screen-sm-min)
	{
		display: none;
	}
}
.helpbuttontop
{

    position: absolute;
    right: 20px;
    top: 9px;
}
.infohover
{
	.text
	{
		display: none;
		background-color: #f0f4f5;
		padding: 15px;
		img
		{
			max-width: 100%;
			max-height: inherit;
		}
	}
}
.helptexttop
{
	display: none;
	background-color: #f0f4f5;
	padding: 15px;
	img
	{
		max-width: 100%;
		max-height: inherit;
	}
}
.select-input
{
	width: 100%;
	float: left;
	margin-bottom: 10px;
	position: relative;
	.custom-select
	{
		display: block !important;
		//width: 100%;
	}
}
.radio-container
{
	//float: left;
}
.radio-input
{
	position: relative;
	width: 31.33%;
	min-width: 120px;
	float: left;
	margin: 1%;
	label, input, select
	{
		font-size: 14px;
	}
	label
	{
		width: 100%;
		min-height: 45px;
		color: black;
		background-color: white;
		border-radius: 0;
		cursor: pointer;
		position: relative;
		span
		{
			min-height: 45px;
			display: block;
			@media(max-width: $screen-slg-min)
			{
				min-height: 60px;
			}
		}
		.overlay
		{
			border: 3px solid #DDD;
			min-height: 150px;
			width: 100%;
			display: inline-block;
			background-size: cover;
			background-position: center;
		}
	}
	input
	{
		display: none;
		position: relative;
		&:checked + .overlay
		{
			border: 3px solid #333;
		}
	}
	@media(max-width: 500px)
	{
		width: 100%;
		label
		{
			min-height: 22px;
			span
			{
				min-height: 22px;
			}
			.overlay
			{
				border: 3px solid #DDD;
				min-height: 150px;
				width: 100%;
				display: inline-block;
				background-repeat: no-repeat;
				background-size: contain;
			}
		}
	}
}
.submit
{
    margin-top: 20px;
    display: inline-block;
    width: 100%;
	padding-right: 1%;
	button, .button
	{
		padding: 1em;
		border: 0;
		color: black;
		background-color: white;
		border-radius: 0;
		float: right;
		text-decoration: none;
		&.forth
		{
			background-color: $brand-primary;
			background-image: url("../img/arrow-right.png");
			background-repeat: no-repeat;
			background-position: 90% center;
			background-size: 8px;
			padding: 0.645em 1.8em 0.645em 1.29em;
		}
		&.forth:hover
		{
			background-color: darken($brand-primary, 10%);
		}
		&.back
		{
			background-color: $text-bg;
			background-image: url("../img/arrow-left.png");
			background-repeat: no-repeat;
			background-position: 15% center;
			background-size: 8px;
			padding: 0.645em 1.29em 0.645em 1.8em;
			-webkit-appearance: inherit;
			&:hover
			{
				background-color: darken($text-bg, 10%);
			}
		}
	}
}


.frm-checkbox {

    margin-bottom: 0.625em;

    .checkbox {
        border: none;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;

        &:checked{

            +.label:before {
            content: "x";
            font-size: 16px;
            line-height: 1.5;
            text-align: center;
            text-transform: uppercase;
            }

        }


        +.label:before {
            content: '';
            color: black;
            position: absolute;
            top: 45%;
            -webkit-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
            transform: translateY(-50%);
            left: 0;
            width: 24px;
            height: 24px;
            border: 1px solid #787878;
            background: white;
            font-size: 18px;
            line-height: 21px;
            text-align: center;
            border-radius: 3px;
            -webkit-box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1) inset;
            box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1) inset;
        }

        +.label {
            color: black;
            position: relative;
            padding: 0 0 0.5em 34px;
            vertical-align: middle;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            margin-left: 6px;
        }
    }

    label, label .label {
        padding: 0;
        margin: 0;
        color: inherit;
        position: relative;
        cursor: pointer;
        -webkit-box-sizing: inherit;
        box-sizing: inherit;
    }
}

.frm_radio{
    display: inline-block;
    padding-bottom: 20px;
}

/* Container used for styling the custom select, the buttom class below adds the bg gradient, corners, etc. */
.custom-select, .dropdown-headline {
	position: relative;
	display: inline-block;
	padding: 0;
	border: 1px solid #787878;
	background-color: white; }

/* This is the native select, we're making everything but the text invisible so we can see the button styles in the wrapper */
.custom-select select, .dropdown-headline select {
	width: 100%;
	margin: 0;
	background: none;
	border: 1px solid transparent;
  outline: none;
  padding: .625rem 2.5rem .625rem .625rem;
	/* Remove select styling */
	appearance: none;
	-moz-appearance: none;
	-webkit-appearance: none;
	/* Font size must the 16px or larger to prevent iOS page zoom on focus */
	line-height: 1.3; }

/* Custom arrow sits on top of the select - could be an image, SVG, icon font, etc. or the arrow could just baked into the bg image on the select. Note this si a 2x image so it will look bad in browsers that don't support background-size. In production, you'd handle this resolution switch via media query but this is a demo. */
.custom-select::after, .dropdown-headline::after {
	content: url(data:image/svg+xml,%3Csvg%20version%3D%221.1%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20xmlns%3Axlink%3D%22http%3A//www.w3.org/1999/xlink%22%20width%3D%2218%22%20height%3D%2228%22%20viewBox%3D%220%200%2018%2028%22%3E%0A%3Cpath%20fill%3D%22currentColor%22%20d%3D%22M16.797%2011.5q0%200.203-0.156%200.359l-7.281%207.281q-0.156%200.156-0.359%200.156t-0.359-0.156l-7.281-7.281q-0.156-0.156-0.156-0.359t0.156-0.359l0.781-0.781q0.156-0.156%200.359-0.156t0.359%200.156l6.141%206.141%206.141-6.141q0.156-0.156%200.359-0.156t0.359%200.156l0.781%200.781q0.156%200.156%200.156%200.359z%22%3E%3C/path%3E%0A%3C/svg%3E);
	width: 50px;
	z-index: 1;
	background-color: white;
	text-align: center;
	line-height: 1;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

/* Hover style */
.custom-select select:focus, .dropdown-headline select:focus {
	-webkit-box-shadow: 0 0 0 2px #4f4f4f;
	box-shadow: 0 0 0 2px #4f4f4f;
	color: black;
	outline: none;
	border-radius: 0; }

/* Set options to normal weight */
.custom-select option, .dropdown-headline option {
	font-weight: normal; }

/* ------------------------------------  */
/* START OF UGLY BROWSER-SPECIFIC HACKS */
/* ----------------------------------  */
/* OPERA - Pre-Blink nix the custom arrow, go with a native select button to keep it simple. Targeted via this hack http://browserhacks.com/#hack-a3f166304aafed524566bc6814e1d5c7 */
x:-o-prefocus, .custom-select::after, .dropdown-headline::after {
	display: none; }

/* IE 10/11+ - This hides native dropdown button arrow so it will have the custom appearance, IE 9 and earlier get a native select - targeting media query hack via http://browserhacks.com/#hack-28f493d247a12ab654f6c3637f6978d5 - looking for better ways to achieve this targeting */
/* The second rule removes the odd blue bg color behind the text in the select button in IE 10/11 and sets the text color to match the focus style's - fix via http://stackoverflow.com/questions/17553300/change-ie-background-color-on-unopened-focused-select-box */
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
	.custom-select select::-ms-expand, .dropdown-headline select::-ms-expand {
		display: none; }
	.custom-select select:focus::-ms-value, .dropdown-headline select:focus::-ms-value {
		background: transparent;
		color: #333333; } }

/* FIREFOX won't let us hide the native select arrow, so we have to make it wider than needed and clip it via overflow on the parent container. The percentage width is a fallback since FF 4+ supports calc() so we can just add a fixed amount of extra width to push the native arrow out of view. We're applying this hack across all FF versions because all the previous hacks were too fragile and complex. You might want to consider not using this hack and using the native select arrow in FF. Note this makes the menus wider than the select button because they display at the specified width and aren't clipped. Targeting hack via http://browserhacks.com/#hack-758bff81c5c32351b02e10480b5ed48e */
/* Show only the native arrow */
@-moz-document url-prefix() {
	.custom-select, .dropdown-headline {
		overflow: hidden; }
	.custom-select select, .dropdown-headline select {
		width: 105%;
		width: calc(100% + em); }
	.custom-select select option, .dropdown-headline select option {
		max-width: 100%; } }

/* Firefox focus has odd artifacts around the text, this kills that. See https://developer.mozilla.org/en-US/docs/Web/CSS/:-moz-focusring */
.custom-select select:-moz-focusring, .dropdown-headline select:-moz-focusring {
	color: transparent;
	text-shadow: 0 0 0 black; }
