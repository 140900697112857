
button{
  padding: 0.65em;
  border: 0;
  color: black;
  background-color: $text-bg;
  border-radius: 0;
  float: left;
  text-decoration: none;
  &:hover{
    cursor: pointer;
    background-color: darken($brand-primary, 10%);
  }
  &.submit-arrow
  {
    background-color: $brand-primary;
    background-image: url("../img/arrow-right.png");
    background-repeat: no-repeat;
    background-position: 90% center;
    background-size: 8px;
    padding: 0.645em 1.8em 0.645em 1.29em;
    min-width: 120px;
    &:hover
    {
      background-color: darken($brand-primary, 10%);
    }
  }
}
.submit-loading {
  background-color: #fd0;
  padding: 0.645em 1.8em 0.645em 1.29em;
  float: right;
  min-width: 120px;
}
.spinner {
  margin: -2.5px auto;
  border-bottom: 2px solid #fff;
  border-left: 2px solid #fff;
  border-right: 2px solid #333;
  border-top: 2px solid #333;
  border-radius: 100%;
  height: 25px;
  width: 25px;
  -webkit-animation: spin .6s infinite linear;
  -moz-animation: spin .6s infinite linear;
  -ms-animation: spin .6s infinite linear;
  -o-animation: spin .6s infinite linear;
  animation: spin .6s infinite linear;
}

@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
    -moz-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    -ms-transform: rotate(359deg);
    transform: rotate(359deg);
  }

}

@-moz-keyframes spin {
  from {
    -moz-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(359deg);
    transform: rotate(359deg);
  }

}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }

}

@-ms-keyframes spin {
  from {
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(359deg);
    transform: rotate(359deg);
  }

}

@-o-keyframes spin {
  from {
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -o-transform: rotate(359deg);
    transform: rotate(359deg);
  }

}