.ng-invalid.ng-dirty, .ng-invalid-greater-than-incli-length{
    border-color: red !important;
    
    &:focus{
        border-color: red !important;
        box-shadow:  0 0 0 2px #ff0000 !important;
    }
}

.ng-valid.ng-valid{
    //border-color: #787878;
    
    &:focus{
        border-color: #787878;
        box-shadow:  0 0 0 2px #4f4f4f;
    }
}

.grey{
    background-color: #6d6d6d !important;
}

.disabled{
    pointer-events: none;
}

.specialBack{
    cursor: pointer;
}

.passwordNotMatching{
     border-color: red !important;
    
    &:focus{
        border-color: red !important;
        box-shadow:  0 0 0 2px #ff0000 !important;
    }
}
