#burger{
    margin-left: 15px;
    margin-bottom: 15px;
}

#sec-nav{
    font-size: 14px;
    padding: 10px;
    width: auto;
    cursor: pointer;
    border: 1px solid #787878;
    background-color: #fff;
    font-weight: 700;
    line-height: 1.25;
    margin-bottom: 2px;
    display: none;

    >span{
        content: url("/img/arrow_down.svg");
        z-index: 1;
        background-color: white;
        text-align: center;
        line-height: 1;
        pointer-events: none;
        position: absolute;
        right: 30px;
        top: 15%;
        width: 20px;

        will-change: transform;
        transition: -webkit-transform .2s ease-in;
        transition: transform .2s ease-in;
        transition: transform .2s ease-in,-webkit-transform .2s ease-in;
        -webkit-transform-origin: 50%;
        -ms-transform-origin: 50%;
        transform-origin: 50%;

        &[rotated=false]{
            transform: rotate(0deg);
        }

        &[rotated=true]{
            transform: rotate(-180deg);
        }
    }
}

@media(max-width: $screen-sm-max) {
    #sec-nav{
        display: block;
    }
    #sec-nav-col {
        display: block;
    }

    .container.content{
        padding-top: 20px;
    }
}
