#result{
    .red {
        color: red;
    }

    .green {
        color: green;
    }


    &>.col{
        padding: 0;
    }

    @media(max-width: $screen-xs-max) {
        h2
        {
            margin-top: 15px;
        }
    }
    h2{
        display: block;
    }

    h1{
        margin-bottom: 10px;
    }

    .row>h2{
        margin-top: 25px;
    }

    .submitresult{
        width: 25%;
        margin-top: 25px;
        float: left;
    }

    form{
        display: inline-block;
    }

    .mobile-table{
        display: none;
    }

    .desktop-table{
        display: table;
    }

    table tr{
        td {
            display: table-cell;
        }
        td:not(.spacer){
            width: 24%;
        }

        td.spacer{
            width: 4%;
        }
    }
    #saveresult, #getPDF{
        width: 50%;
        float: left;

        &>button{
            width: 100%;
            min-width: 0;
            padding: 0.8em 1.8em 0.8em 1.29em;
        }

        .submit-loading{
            width: 100%;
            float: right;
            min-width: 0 !important;
            padding: 0.8em 1.8em 0.8em 1.29em;
        }
        .saved
        {
                background-color: $brand-primary;
                cursor: default;
        }
        #print-pdf{
            width: 100%;
            background-color: $light_grey;
            padding: 0.8em 1.8em 0.8em 1.29em;

            &:hover, &:focus{
                background-color: darken($light_grey, 10%);
            }
        }

        .pdf-loading{
            width: 100%;
            float: right;
            min-width: 0 !important;
            background-color: $light_grey;
            padding: 0.8em 1.8em 0.8em 1.29em;
        }
    }

    .submit-loading
    {
        float: left;
    }

    div.disabledSelect::after{
        content: '' !important;
    }

    #buttons {
        padding-top: 20px;
    }



    #displayedMotor{
        background-color: $light_grey;
        margin: 15px 0px;
        padding: 15px 0;
        img
        {
            width: 100%;
        }
        h4
        {
            font-size: 15px;
        }
        table
        {
            tr
            {
                background: none;
                td, th
                {
                    border: none;
                    padding: 0;
                }
            }
        }
    }

    .heading{
        margin-top: 50px;
    }

    .styled-table table tbody{
        td:nth-child(3), td:nth-child(4){
            border-left: none;
        }
    }

    .no-found-table{
        line-height: 40px;

        tr{
            padding: 7px;
            border-width: 1px;
            border-color: darken($light_grey, 10%);
            border-top-style: solid;
            border-bottom-style: solid;

            td{
                padding-left: 15px;

                button{
                    float: right;
                }
            }

            td:nth-child(1), td:nth-child(4){
                font-weight: bold;
            }

            td:nth-child(2), td:nth-child(5){
                text-align: right;
                padding-right: 10px;
            }

            &:nth-child(odd){
                background-color: $light_grey;
            }
        }

        .table-button{
            padding-left: 15px;
        }
    }

    tr:not(.first-row-appli) {
        td:nth-child(2), td:nth-child(5), td:nth-child(7) {
            text-align: right;
            padding-right: 10px;
        }
    }

    .motorType{
        line-height: 1.8;
    }

    #loadHoldingReq{
        text-align: left !important;
    }

    #motorDetails{
            table
            {
                margin: 0;
            }
            tr
            {
                vertical-align: top;
            }
            td
            {
                padding-top: 1px;
                padding-bottom: 1px;
            }
        }
}
#new-calc{
    margin-top: 20px;
    padding: 15px;
    text-align: center;
    background-color: $brand-primary;
    cursor: pointer;
}

.spinner.result-spinner{
    width: 80px;
    height: 80px;
}
.table_buttons
{
    line-height: 39px;
    button
    {
        margin-left: 5px;
    }
}

#contact-box{
    margin-top: 30px;
    background-color: $light_grey;
    padding: 15px;

    #contact-button{
        background-color: $brand-primary;
        padding: 5px 20px;
        padding-right: 30px;
        border-radius: 20px;
        width: 70%;
        font-weight: bold;
        margin-bottom: 15px;
        cursor: pointer;
        color: #333333;

        &:hover, &:focus{
            color: #333333;
            text-decoration: none;
        }

        background-image: url("../img/arrow-right.png");
        background-repeat: no-repeat;
        background-position: 90% center;
        background-size: 8px;
    }
}

@media(max-width: $screen-xs-max){

    #result{
        .mobile-table{
            display: table;
        }

        .desktop-table{
            display: none;
        }

        #buttons{
            position: relative;
            right: 0;
            top: 0;

        }


        &>div>.row{
            margin-bottom: 15px;
        }

        .left-table{
            padding-right: 15px !important;
        }

        .right-table{
            padding-left: 15px !important;
        }

        #motorDetails .col.col-sm-6 {
            margin-top: 20px;
        }


        .pcdForm{
            width: 50%;

            input{
                width: 50%;
            }
        }


    }

}

@media(min-width: $screen-sm-min) {
    #result{
        .right-table{
            margin-left: -1px;
        }
    }
}
.pcdForm{
    width: 60%;
    line-height: initial;
}

.editPCD
{
    padding: 9px 12px;
    border: 0;
    color: black;
    background-color: #fd0;
    border-radius: 0;
    float: right;
    text-decoration: none;
    display: inline-block;
    margin: 2px 0 0 0;
    line-height: initial;
}
.result_table_input
{
    //float: left;
    margin-bottom: 10px;
    position: relative;

    &>*{
        float: right;
    }

    span{
        margin-right: 5px;
    }



    label:focus, input:focus
    {
        -webkit-box-shadow: 0 0 0 2px #4f4f4f;
        box-shadow: 0 0 0 2px #4f4f4f;
        color: black;
        outline: none;
    }
    input
    {
        border: 1px solid #787878;
        color: black;
        background-color: white;
        border-radius: 0;
        padding: 9px;
        display: inline-block;
        float: right;
        width: 60%;
        text-align: right;
        margin-right: 5px;
    }
    .unit, .value
    {
        display: inline-block;
        //float: left;
    }
    .unit
    {
        margin-right: 5px;
    }
}
.noMotorFound
{
    background-color: $light_grey;
    padding: 15px;
}
