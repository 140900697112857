.arrow
{
	height: 20px;
	width: 20px;
	position: absolute;
	left: -5px;
	top: 25px;
	border-right: 20px solid #f0f4f5;
	border-top: 20px solid white;
	border-bottom: 20px solid white;
	@media(max-width: $screen-xs-max)
	{
		display: none;
	}
}
.description
{
	background-color: #f0f4f5;
	min-height: 412px;
	padding: 30px;
	img	
	{
		width: 100%;
	}
	@media(max-width: $screen-xs-max)
	{
		display: none;
	}
}