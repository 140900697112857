$font-family-base : "Futura", Helvetica, Arial, sans-serif;
$brand-primary: #fd0;
$primary-color: #1394c2;
$secondary-color: #1394c2;
$light_grey: #f0f4f5;

//$h1-fontsize: 2.5rem !default;
//$h2-fontsize: 2rem !default;
//$h3-fontsize: 1.8rem !default;
//$h4-fontsize: 1.4rem !default;
//$h5-fontsize: 1.2rem !default;
//$h6-fontsize: 1rem !default;

$navcolorbg: $brand-primary;

$grid-gutter-width: 30px;


$container-slg: 1525px + $grid-gutter-width;
$screen-slg-min: $container-slg;

/*

$container-sm:            ((720px + $grid-gutter-width));

// Medium screen / desktop
$screen-md-min: ((1240px + $grid-gutter-width));
$container-md:           ((1240px + $grid-gutter-width));

// Large screen / wide desktop

$screen-lg-min: 1525px + $grid-gutter-width;
$container-lg:        ((1525px + $grid-gutter-width));
*/


$text-bg: #f0f4f5;

@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap/variables";
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap/mixins";
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap/normalize";
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap/scaffolding";
@import "_grid";
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap/tables";
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap/pagination";
@import "node_modules/font-awesome/css/font-awesome";
@import "node_modules/flexslider/flexslider";
@import "_administration";
@import "_global";
@import "_layout";
@import "_interroll_navbar";
@import "_interroll_sidebar";
@import "_inputs";
@import "_description";
@import "_validation";
@import "_mobile_menu";
@import "_result";
@import "_calculations";
@import "_btn";
@import "_overlay";
@import "_teaser";
@import "_matchmaker-checkbox";
@import "components/_table";

.unreachable{
    color: #aaa !important;
}

.configurator .row
{
	margin-left: -15px;
	margin-right: -15px;
	&:before
	{
		clear: both;
	}
	h1
	{
		font-size: 35px;
	}
}

.sidenav ol.nav-level1 > li a {
	text-decoration: none;
}

.wmdb-bdmm-wrap.wmdb-bdmm-wrap {
  padding: 30px;

  input {
    background: #e8e8e9;
    width: 100%;
    padding: 10px;
    border-radius: 0;
    border: 0;
  }

  .btn {
    padding: 7px 20px;

    .text {
      color: #fff;
    }

    &:hover {
      background: #000;
    }

    &:after {
      content: none;
    }
  }

  button {
    text-align: center;
  }
}

.pagination {
	> .active {
		> a, > span {
      &, &:hover, &:focus {
        color: #fff !important;
      }
    }
  }
}
