.flexslider {
  background-color: $light_grey;
}

.config-teaser.flexslider{
  border: none;
  padding: 15px;

  &>ul{
    margin-left: 0 !important;
    li::before{
      content: '' !important;
    }
  }

  li .col:first-child{
    text-align: center;
    padding-right: 0;

    img{
      max-width: 100%;
      max-height: 100%;
    }
  }

  li{
    background-color: $light_grey;
    padding:14px;

    h4{
      word-break: break-word;
    }

    p{
      margin: 0;
    }
  }

}

@media only all and (min-width: 48em) {
  .config-teaser.flexslider{
    li{
      padding:14px 0;
    }
  }
}

