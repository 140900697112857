table {
    width: 100%;

    tr.softdeleted {
        td
        {
            text-decoration: line-through;
            font-style: italic;
        }
        td.options
        {
            text-decoration: none;
            font-style: normal;
        }
    }

    a.disabled[href="#"]
    {
        color: grey;
        font-style: italic;
    }
}

.icon-btn {
	border: 0;
	color: black;
  background-color: $brand-primary;
  margin-left: 0.2em;
  width: 30px;
  height: 30px;
  display: inline-block;
  text-decoration: none;
    &:hover {
        background-color: darken($brand-primary, 10%);
        color: lighten($text-bg, 10%);
    }
    i
    {
      margin: 8px 10px;
    }
}



.btn
{
    display: inline-block;
	padding: 1em 2em;
	border: 1px solid darken($text-bg, 10%);
	color: black;
	background-color: white;
	border-radius: 0;
    &:hover, &:focus, &:active
    {
      color: black;
      background-color: darken(white, 10%);
      text-decoration: none;
    }
}
.btn.pdf_admin_export
{
  padding: 4px 2em;
}

.text-input-flex
{
	margin-bottom: 10px;
	position: relative;
	label, input, select
	{
		width: 100%;
		display: inline-block;
	}
	label:focus, input:focus
	{
		-webkit-box-shadow: 0 0 0 2px #4f4f4f;
		box-shadow: 0 0 0 2px #4f4f4f;
		color: black;
		outline: none;
	}
	input
	{
		padding: 1em;
		border: 1px solid #787878;
		color: black;
		background-color: white;
		border-radius: 0;
	}

}

.text-right {
    text-align: right;
}

.explanation {
    color: darken($text-bg, 10%);
    padding-left: 1em;
}
h1.withButton, form.button
{
  display: block;
  float: left;
}
form.button
{
  margin-left: 15px;
}